.terms-and-conditions {
    width: 100%;
    height: 100%;
    background: white;
    padding: 1em 3em;
}

.terms-and-conditions h4 {
    text-align: center;
    margin: 1em 0;
}

p.terms-and-conditions:first-of-type {
    text-align: center;
    margin-bottom: 1em;
}

.terms-and-conditions li {
    margin: 1em 0;
}

li.indent {
    margin-left: 1em;
}

span.underline {
    text-decoration: underline;
}