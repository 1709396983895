#hero-section {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.open-logo {
    width: 90%;
    padding: 1em;
    margin-bottom: 1em;
}

.welcome-text p {
    color: white;
    font-weight: bold;
    font-size: 1.125rem;
    margin: 0 auto;
    padding: 1em;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    line-height: 1.4;
    text-align: center;
    text-shadow: 0 0 1px white;
    max-width: 90%;
}

.legal-text {
    color: white;
    text-align: center;
    width: 97%;
    font-size: .625rem;
    margin-bottom: 1em;
    text-shadow: 0 0 1px white;
}

/* this is for the fake link, should remove this once the real link is added */
.legal-text a {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.pro-logo {
    margin: 2em 0 4em;
}

.pro-logo img {
    margin: 0 auto;
    width: 65%;
}

@media screen and (min-width: 768px) {
    .open-logo {
        width: 33%;
    }
    .welcome-text p {
        font-size: 1.25rem;
        max-width: 70%;
        padding: 1em 0;
    }
    .legal-text {
        width: 50%;
        margin: 1em auto;
    }
    .legal-text p:nth-of-type(2) {
        font-size: 0.625rem;
    }
}