.navbar {
    position: fixed;
    background-color: var(--teal);
    width: 100%;
    height: 50%;
    padding: 1em 0;
    margin-top: 50px;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    z-index: 1002;
    transform: translateY(-150%);
    transition: all 0.4s ease-in-out;
    z-index: 101;
}

.navbar.open {
    transform: translateY(0);
}

.nav-items {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 1em;
    gap: 2em;
}

.nav-items a {
    text-decoration: none;
    color: black;
}

.nav-item {
    border-bottom: 2px solid black;
    font-size: 3rem;
    font-weight: bold;
}

@media screen and (min-width: 768px) {
    .navbar {
        height: revert;
    }
    .nav-items {
        flex-direction: row;
        align-items: center;
        padding: 1em 0;
    }
    .nav-item {
        font-size: 3rem;
    }
}