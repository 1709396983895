body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #252525; /* fallback bg */
  background: url("https://images.complex.com/complex/image/upload/q_auto/v1717189030/Custom/michelob/main-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center top;
  min-height: 100vh;
  cursor: url('../src/images/mich-cursor-sm.png'), auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html { scroll-behavior: smooth; }

:root {
  --teal: #00d8bf;
}