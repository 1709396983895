/* Universal Form Styling */
.form-styling {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    margin: 1em auto;
}

/* Universal Input Styles */
.form-styling input,
.form-styling button,
.form-styling label,
.form-styling select {
    margin: 0;
    padding: 0.5em;
}

input, select {
    width: 100%;
    max-width: 240px;
    font-family: inherit;
    background-color: transparent;
    color: white;
    font-weight: bold !important;
    border: 2px solid white;
    cursor: pointer;
    text-align: center;
}

select {
    font-weight: bold !important;
}

input::placeholder {
    color: white !important;
    text-align: center;
    font-weight: bold !important;
}
input::-moz-placeholder {
    color: white !important;
    opacity: 1;
}

input[type=date] {
    text-align: center;
    text-transform: uppercase;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

input[type=number] {
    font-weight: bold;
}

button[type=submit] {
    outline-color: var(--teal);
}

input#birthdate {
    font-weight: bold;
}

/* Teal highlights */
input:focus, input:active {
    outline-color: var(--teal);
}

/* Teal Highlights for Calendar */
input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
    background-color: rgba(0, 216, 191, 0.5);
    color: white;
    outline: 1px solid var(--teal);
}
input[type="date"]:focus::-moz-datetime-edit-day,
input[type="date"]:focus::-moz-datetime-edit-month,
input[type="date"]:focus::-moz-datetime-edit-year { /* Firefox */
    background-color: rgba(0, 216, 191, 0.5);
    color: white;
    outline: 1px solid var(--teal);
}

/* Opt-in Styles */
.opt-in {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5em;
    margin: .25em auto;
    text-align: center;
}
.opt-in label {
    text-align: center;
    color: white;
    font-size: 0.625rem;
    font-weight: bold;
    padding: .25em 0;
    max-width: 500px;
}
.opt-in a, .opt-in a:visited {
    color: white;
}


.checkbox-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input[type="checkbox"] {
    margin: 0;
    height: 30px;
    width: 30px;
    opacity: 0.8;
    display: block;
    position: relative;
}

input[type="checkbox"]:checked {
    opacity: 1;
}

.submit-btn {
    padding: .75rem 3rem;
    border: none;
    background-color: black;
    color: white;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    max-width: 240px;
    align-self: center;
    transition: all 0.3s ease-in-out;
}
.submit-btn:hover {
    background-color: white;
    color: black;
}

/* Error Message styles */
.formik-error {
    text-align: center;
    font-size: 1.25rem;
    text-transform: uppercase;
    padding: .5em;
    color: white;
}
.formik-error:before,
.formik-error:after {
    content: "**";
    color: black;
}

/* Success Message styles */
.success-message {
    padding: 2em 0;
    font-size: 2rem;
    color: white;
    text-align: center;
    text-shadow: 0 0 1px white;
}

/* Media Queries */
@media screen and (min-width: 768px) {
    #score-form {
        width: 45%;
    }

    .opt-in label {
        font-size: .75rem;
    }

    .submit-btn {
        font-size: 1.25rem;
    }

    #secondary-form {
        max-width: 500px;
    }
}
