.leaderboard {
    display: flex;
    flex-direction: column;
    border-right: 1px solid white; /* Right border for separation */
    padding: 1em; 
    min-height: 100%;
    flex: 1;
}

.leaderboard-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-right: 1px solid white; /* Right border for separation */
    border-bottom: 1px solid var(--teal);
}

.leaderboard-slider.expanded .leaderboard-column {
    flex: 1;
}

.lb-wrap {
    display: flex;
    flex-direction: column;
}

.lb-wrap.additional-records {
    display: none; /* Hide additional records to start && on mobile */
}

.lb-header {
    padding: 1em;
    text-align: center;
    margin-bottom: 1em;
    min-height: 150px; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
    border-bottom: 1px solid white; 
}

.lb-header h2 {
    font-family: "meno-banner-extra-condensed", serif;
    font-weight: bold;
    color: var(--teal);
    font-size: 1.5rem;
    text-shadow: 0 0 1px white; /* readability */
}

.lb-header p, .lb-header a {
    font-size: 0.75rem;
    font-weight: bold;
    color: white; /* readability */
    padding: 0.75em 0;
}

.loading-gif {
    display: none;
}
.loading-msg {
    margin: 1em auto;
    text-align: center;
    min-height: 50px;
    padding: 0.5em;
}
.loading-msg img {
    width: 100%;
    height: auto;
}


/* POP-UP */
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 75%;
    background-color: rgba(0, 216, 191, 0.95);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
    border: 1px solid white;
    font-weight: bold;
    text-shadow: 0 0 1px black;
    z-index: 10;
}

.pop-close {
    border: none;
    background: rgba(0, 216, 191, 0.3);
    color: black;
    position: absolute;
    top: 0; 
    right: 2%;
    font-size: 2.5rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.pop-close:hover {
    transform: rotate(90deg);
}

.popup-inner {
    text-align: center;
    color: black;
    padding: 1em;
}

.popup h3 {
    font-family: 'meno-banner-extra-condensed', sans-serif;
    font-size: 1.75rem;
    margin: .25em auto;
}

.instructions p {
    font-weight: normal;
    margin: .5em 0;
}

.instructions a {
    color: black;
}

.grint-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.grint-message {
    display: flex;
    align-items: center;
    gap: 1em; 
}

.instructions {
    display: flex;
    flex-direction: column;
    gap: .5em;
}

.instructions > div {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.instructions > div p {
    margin: 0;
}

.instructions > div img {
    width: auto;
    max-height: 40px; 
    margin: 0;
    display: block;
}


@media screen and (min-width: 768px) {
    .lb-header {
        padding: 1em 2em;
        min-height: 150px;
    }
    .leaderboard-column {
        width: 100%;
        margin-bottom: 1em;
        border-bottom: 1px solid var(--teal); /* Bottom border for vertical separation */
        scrollbar-color: var(--teal) #252525;
    }
    .leaderboard-column::-webkit-scrollbar-track {
        background: #252525;
    }
    .leaderboard-column::-webkit-scrollbar-thumb {
        background-color: var(--teal);
    }

    .leaderboard-slider.expanded .leaderboard-column {
        border-bottom: none;
        overflow-y: scroll;
    }
    .leaderboard-slider.expanded .lb-wrap.additional-records {
        display: flex;
    }

    .popup {
        width: 50%;
        height: 65%;
    }

    .popup h3 {
        font-size: 1.5rem;
        width: 75%;
    }

    .loading-gif {
        display: block;
    }
    .loading-msg {
        min-height: 100px;
        padding: 1em;
    }
}
