.leaderboard-slider {
  width: 25%;
  transition: all 0.3s ease;
  overflow: hidden;
  background-color: black;
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: all 1s ease-in-out;
}
  
.leaderboard-slider.expanded {
  width: 75%;
}

.leaderboard-container {
  overflow-y: auto;
}

.leaderboard-slider.expanded .leaderboard-container {
  display: flex;
  flex-direction: column;
}

.total-players {
  width: 100%;
  background-color: lightgrey;
  text-align: center;
  padding: 4px 0;
  background: linear-gradient(270deg, #00d8bf, #252525, #252525, #00d8bf);
  background-size:400% 400%;
  -webkit-animation: gradient-scroll 15s ease infinite;
  -moz-animation: gradient-scroll 15s ease infinite;
  animation: gradient-scroll 15s ease infinite;
  z-index: 0;
}

.total-players p {
  color: white;
  font-weight: 900;
  font-size: 1rem;
  margin: .125em 0;
  font-family: 'neue-haas-unica', sans-serif;
}

@-webkit-keyframes gradient-scroll {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes gradient-scroll {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes gradient-scroll {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

@media screen and (min-width: 400px) {
    .leaderboard-slider {
      width: 25%;
    }
  }
  
@media screen and (min-width: 768px) {
  .leaderboard-slider {
    min-width: 400px;
  }
  .leaderboard-slider.expanded {
    width: 75%;
  }
  .leaderboard-slider.expanded .leaderboard-container {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
  }
}