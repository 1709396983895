.footer-container {
    background-color: black;
    font-family: neue-haas-unica, sans-serif;
    line-height: 1.2;
    flex-direction: row;
}

.footer-wrapper {
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 2em 1em 0;
}

.footer-privacy-container {
    order: 2;
    display: flex;
    flex: 1 1 65%;
    flex-direction: column;
}

.footer-logo {
    height: 32px;
    width: auto;
    margin-bottom: 20px;
}

.footer-links {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #232323;
    font-size: 12px;
    margin-bottom: 1em;
}

.footer-links a {
    text-decoration: none;
    color: #9b9b9b;
}

.footer-links a:nth-of-type(n) {
    margin-bottom: 20px;
}

.footer-complex-logo {
    opacity: 0.6;
}

.footer-complex-logo img {
  height: 40px;
  width: auto;
}

.footer-legal-wrapper {
    color: #9b9b9b;
    font-size: 12px;
    max-width: 1240px;
    margin: 0 auto;
    padding: 2em 1em 1em;
    flex-direction: row;
}

@media screen and (min-width: 768px) {
    .footer-wrapper {
        flex-direction: row;
    }

    .footer-privacy-container {
        order: 1;
    }

    .footer-links {
        flex-direction: row;
        justify-content: space-between;
    }
}