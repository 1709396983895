#secondary-form {
    max-width: 300px;
    position: relative;
}

.secondary-form-popup {
    position: absolute;
    background-color: black;
    color: white;
    padding: 3em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-height: 500px;
    border: 1px solid white;
    z-index: 1000;
}

.secondary-form-popup .form-styling .optional-info p {
    color: white;
    font-weight: bold;
}
.secondary-form-popup .form-styling button {
    border: 1px solid white;
}


/* Leaderboard Popups */
.secondary-form-leaderboard form.form-styling input,
.secondary-form-leaderboard form.form-styling select {
    color: black;
    border: 2px solid black;
}

.secondary-form-leaderboard form.form-styling input::placeholder {
    color: black !important;
}
.secondary-form-leaderboard form.form-styling input::-moz-placeholder {
    color: black !important;
    opacity: 1;
}

.optional-info p {
    color: black;
    text-align: center;
    font-size: 1rem;
    text-shadow: 0 0 1px white;
}


@media screen and (min-width: 768px) {
    #secondary-form {
        max-width: 500px;
    }
}