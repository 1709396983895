.main-content-wrapper {
  display: flex;
  height: calc(100vh - 50px);
  width: 100vw;
  margin-top: 50px;
  transition: all 0.3s ease;
}

.main-content {
  flex: 1;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.main-content::-webkit-scrollbar {
  display: none;
}  