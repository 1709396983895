.toggle-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 100%;
    background-color: white;
    cursor: ew-resize;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

.toggle-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; /* needed to center content vertically */
    text-align: center;
}

.toggle-icon {
    width: 10px;
    height: auto;
    margin: 1em 0;
}

.toggle-text {
    writing-mode: vertical-lr;
    font-size: 0.75rem;
    font-weight: 900;
    color: black;
    user-select: none; /* Prevent text selection during toggle */
}
