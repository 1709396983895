.player {
    border-bottom: 1px solid white;
    padding: 1em;
    display: flex;
    justify-content: space-around;
    font-weight: bold;
    min-height: 110px;
}

.course, .location {
    font-size: 0.75rem;
    margin-bottom: .5em;
}

.badge-logo {
    height: 30px;
}

.badge-logo img {
    height: 100%;
}

.player-stats {
    min-width: 120px;
}

.player-score {
    min-width: 100px;
    font-size: 2rem;
    align-self: center; 
    flex-basis: 50px; 
    text-align: right; /* Ensure text alignment is right */
}