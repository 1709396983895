header {
    background: white;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 102;
}

.logo {
    padding: 1em;
    width: 90%;
}

.menu-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 25px;
    cursor: pointer;
    margin-right: 1.5em;
    z-index: 10;
}

.bar {
    background-color: black;
    height: 3px;
    width: 100%;
    min-width: 24px;
    transition: all 0.3s ease-in-out;
}

.menu-icon.open .top-bar {
    transform: rotate(45deg) translate(5px, 5px);
}

.menu-icon.open .middle-bar {
    opacity: 0;
}

.menu-icon.open .bottom-bar {
    transform: rotate(-45deg) translate(7px, -7px);
}


@media screen and (min-width: 768px) {
    .logo {
        width: 60%;
        padding: 1.5em;
    }
}