.gate-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 216, 191, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.age-gate {
    background: black;
    color: white;
    border: 1px solid white;
    position: absolute;
    width: 95%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 .5em;
    z-index: 1002;
}

.age-gate h1 {
    margin-top: auto;
    margin-bottom: .25em;
    padding: .5em;
    font-size: 2.75rem;
    text-align: center;
}

.instruction-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;
    font-weight: bold;
}

.instruction-text p {
    font-size: .6125rem;
}

.age-gate input[type=date] {
    width: 225px;
    padding: 1em 1.5em;
    margin: 1em 0;
    background-color: transparent;
    border: 2px solid white;
    color: white !important;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1rem;
}

.age-gate input[type=date]::placeholder {
    color: white !important;
    text-align: center;
    font-weight: bold !important;
}

.age-gate button {
    margin: 1em 0 2em;
    width: 225px;
    padding: .5em .25em;
    background-color: transparent;
    border: 2px solid white;
    color: white;
    font-weight: bold;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.2s ease;
}
.age-gate button:hover {
    background-color: white;
    color: black;
}
.age-gate button:focus {
    outline-color: var(--teal);
}

.gate-footer {
    margin-top: auto;
}

.legal-links a {
    font-size: .75rem;
    font-weight: bold;
    color: white;
    margin: 2em 1em;
}

.gate-footer img {
    margin: 0 auto;
    width: 90%;
    padding: 1em;
}

.gate-footer p {
    text-align: center;
    font-size: 6px;
    padding: 1em;
    font-weight: bold;
    margin-bottom: .25em;
}

@media screen and (max-width: 400px) {
    .age-gate input[type="date"]:before {
        content: attr(placeholder) !important;
        margin: 0 2em;
        display: block;
        position: absolute;
    }
    .age-gate input[type=date].has-value::before {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .age-gate {
        width: 70%;
    }
    .age-gate h1 {
        font-size: 3.5rem;
    }
    .instruction-text {
        flex-direction: row;
        gap: 2em;
    }
    .age-gate input[type=date] {
        font-size: 1.375rem;
        padding: .5em 1em;
    }
    .age-gate button {
        font-size: 1.375rem;
    }
    .gate-footer img {
        width: 70%;
    }
    .gate-footer p {
        font-size: .6125rem;
        font-weight: normal;
    }
}