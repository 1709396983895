.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}


/*
  Josh Comeau's CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
input, button, textarea, select {
  font: inherit;
  margin: 0;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
#root, #__next {
  isolation: isolate;
}

/* GLOBALS */

.section-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1em .25em;
}

.section-title p {
  display: flex;
  margin: 0;
  padding: 0 1em;
  font-size: .8rem;
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 8px #252525;
  align-self: flex-end;
}

.section-title hr {
  flex-grow: 1;
  border: 1px solid white;
  height: 1px;
  text-shadow: 1px 1px 8px #252525;
  margin: 0 .5em;
}


@media screen and (min-width: 768px) {
  .section-title p {
    font-size: 1rem;
  }
}
